import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { Link } from "react-router-dom";
import { faEnvelope, faLocationDot, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import Example from "./slider";
import Other2 from "./other2";
import Othe from "./footer";
import { FaHeart, FaComments, FaEye } from "react-icons/fa";
import { Navbar } from "flowbite-react";
import { Dropdown } from "flowbite-react";
const Home = () => {


    return (
        <>

            {/* <div className="hader-top bg-[#ba9449] opacity-75">
                <div className="contaner">
                    <div className="flex justify-between">
                        <div className="location flex col-span-2">
                            <FontAwesomeIcon icon={faLocationDot} className="text-[#003C43] mt-1" />
                            <p className="text-white ml-3 font-poppins ">23 Ranking Street, New York</p>
                        </div>
                        <div className="email flex ml-4">
                            <FontAwesomeIcon icon={faEnvelope} className="text-[#003C43] mt-1" />
                            <p className="text-white ml-3 font-poppins ">Email@Example.com</p>
                        </div>
                        <div className="h-[31px]">
                            <marquee width="800px" direction="right" height="60px" behavior="scroll" scrollamount="3">
                                <p className="text-[#000000] font-poppins">Note : We help you to Grow your Business</p>
                            </marquee>
                        </div>
                        <div className="flex">
                            <FontAwesomeIcon icon={faFacebook} className="text-[#003C43] mt-1 ml-3" />
                            <FontAwesomeIcon icon={faTwitter} className="text-[#003C43] mt-1 ml-3" />
                            <FontAwesomeIcon icon={faInstagram} className="text-[#003C43] mt-1 ml-3" />
                            <FontAwesomeIcon icon={faLinkedin} className="text-[#003C43] mt-1 ml-3" />
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="">

                <div className="hader bg-[#ba9449] w-[100%] py-[20px]">
                    <div className="contaner">
                        <Navbar className="bg-[#ba9449] justify-between">
                            <Navbar.Brand href="#">
                                <div className="box-border	min-[767px]:w-[300px] min-[1020px]:w-[500px]">
                                    <p className='text-[black] text-[25px] pb-[10px] mt-[15px] '><span className='text-[#ffffff]'>TECH</span>BLOG</p>
                                </div>
                            </Navbar.Brand>
                            <div className="flex md:order-2  min-[767px]:hidden">
                                <Navbar.Toggle />
                            </div>
                            <Navbar.Collapse className="min-[767px]:block">
                                <div className="grid grid-cols-1 gap-2 min-[767px]:grid-cols-6 min-[767px]:gap-0">
                                    <Link to="/" className="hover:text-[#000000] text-[#ffffff]">Home</Link>
                                    <Link to="/About" className="hover:text-[#000000] text-[#ffffff]" >About</Link>
                                    <Link to="/Blog" className="hover:text-[#000000] text-[#ffffff]" >Blog</Link>
                                    <Link to="/Privacy" className="hover:text-[#000000] text-[#ffffff]" >Privacy Policy</Link>
                                    <Link to="/Contact" className="hover:text-[#000000] text-[#ffffff]" >Contact Us</Link>

                                </div>
                            </Navbar.Collapse>
                        </Navbar>
                    </div>
                </div>
                <div className="">
                    <Example />
                </div>
            </div>


            <div className='block2 mt-[100px]'>
                <div className="contaner">
                    <div className="main grid grid-cols-1 gap-4 min-[600px]:grid-cols-2 min-[1020px]:grid-cols-3  min-[600px]:gap-5">

                        <div className="box1 hover:translate-y-[-30px] duration-700 shadow-xl bg-[#ffffff]  box-border  w-[100%] h-[530px] p-[20px] ">
                            <Link to='/Blog1'>
                                <div className="img">
                                    <img src="../img/Technology.avif" alt="" />
                                </div>
                                <div className="hadeing mt-2">
                                    <p className='text-[30px]'><a href="#">Technology</a></p>

                                    <p className='text-[20px] font-semibold hover:text-[#bd923b] hover:underline mt-[12px]'>How a developer and designer duo at Deutsche Bank keep remote alive.</p>

                                    <p className='mt-[12px]'>A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy.</p>
                                </div>
                            </Link>
                            <div className="readmore mt-[10px]">
                                <button className="text-orange-600 hover:text-orange-800">
                                <a href="https://echonverforrinho.info?tid=1113686">Read More →</a>
                                    
                                </button>
                            </div>
                        </div>

                        <div className="box2 hover:translate-y-[-30px] duration-700 shadow-xl bg-[#ffffff]  box-border  w-[100%] h-[530px] p-[20px]">
                            <Link to='/Blog2'>
                                <div className="img">
                                    <img src="../img/run2.avif" alt="" />
                                </div>
                                <div className="hadeing mt-2">
                                    <p className='text-[30px]'><a href="#">Inspiration</a></p>

                                    <p className='text-[20px] font-semibold hover:text-[#bd923b] hover:text-[red] hover:underline mt-[12px]'>Inspire Runner with Autism Graces the Latest Cover of Women's Running</p>

                                    <p className='mt-[12px]'>A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy.</p>
                                </div>
                            </Link>
                            <div className="readmore mt-[10px]">
                                <button className="text-orange-600 hover:text-orange-800">
                                    <a href="https://echonverforrinho.info?tid=1113686">Read More →</a>
                                </button>
                            </div>
                        </div>
                        <div className="box3 hover:translate-y-[-30px] duration-700 shadow-xl bg-[#ffffff]  box-border  w-[100%] h-[530px] p-[20px] pb-[20px] ">
                            <Link to='/Blog3'>
                                <div className="img">
                                    <img src="../img/Public.avif" alt="" />
                                </div>
                                <div className="hadeing mt-2">
                                    <p className='text-[30px]'><a href="#">Public</a></p>

                                    <p className='text-[20px] font-semibold hover:text-[#bd923b] hover:underline mt-[12px]'>We Provide Services To Grow Your Business Sell Affiliate Products.</p>

                                    <p className='mt-[12px]'>A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy.</p>
                                </div>
                            </Link>
                            <div className="readmore mt-[10px]">
                                <button className="text-orange-600 hover:text-orange-800">
                                <a href="https://echonverforrinho.info?tid=1113686">Read More →</a>
                                    
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="blogsection  show-after-visible from-bottom">
                <section class="py-10">
                    <div class="contaner ">
                        <div class="text-2xl text-center font-semibold mb-10  hover:underline text-[#a17d34]"> ALL BLOG</div>
                        <div class="grid lg:grid-cols-3 gap-6">
                            <div class="relative group">
                                <Link to="/Blogpage1">
                                    <div class="overflow-hidden rounded-lg aspect-w-16 aspect-h-9">
                                        <img src="/img/download.jpg" className='object-cover w-full h-[250px] transition-all duration-300 transform group-hover:scale-125' alt="" />
                                    </div>
                                    <p class="mt-6 text-sm font-normal text-zinc-600 font-pj">November 9, 2024</p>
                                    <p class="mt-4 text-xl font-bold text-zinc-900 font-pj text-[#000000] ">Amazon Delivery Drone Noise Annoys Some Residents of Texas Town</p>
                                </Link>
                                <div className="readmore mt-[10px]">
                                    <button className="text-orange-600 hover:text-orange-800">
                                        <a href="https://echonverforrinho.info?tid=1119179">Read More →</a>
                                    </button>
                                </div>
                            </div>
                            <div class="relative group">
                                <Link to="/Blogpage">
                                    <div class="overflow-hidden rounded-lg aspect-w-16 aspect-h-9">
                                        <img src="/img/ai2.jpg" className='object-cover w-full h-[250px] transition-all duration-300 transform group-hover:scale-125' alt="" />
                                    </div>
                                    <p class="mt-6 text-sm font-normal text-zinc-600 font-pj">November 8, 2024</p>
                                    <p class="mt-4 text-xl font-bold text-zinc-900 text-[#000000]">The best free AI tools in 2025 </p>
                                </Link>
                                <div className="readmore mt-[10px]">
                                    <button className="text-orange-600 hover:text-orange-800">
                                       <a href="https://echonverforrinho.info?tid=1119179">Read More →</a> 
                                    </button>
                                </div>
                            </div>
                            <div class="relative group">
                                <Link to="/Blogpage3">
                                    <div class="overflow-hidden rounded-lg aspect-w-16 aspect-h-9">
                                        <img src="/img/ai.jpg" className='object-cover w-full h-[250px] transition-all duration-300 transform group-hover:scale-125' alt="" />
                                    </div>
                                    <p class="mt-6 text-sm font-normal text-zinc-600 font-pj">November 16, 2024</p>
                                    <p class="mt-4 text-xl font-bold text-zinc-900 font-pj text-[#000000]">AI For Website Building: Tools, Tips, and Trends for 2024</p>
                                    <a href="#" title="">
                                        <span class="absolute inset-0" aria-hidden="true"></span>
                                    </a>
                                </Link>
                                <div className="readmore mt-[10px]">
                                    <button className="text-orange-600 hover:text-orange-800">
                                       <a href="https://echonverforrinho.info?tid=1119179">Read More →</a> 
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className="block">
                <div className="contaner">
                    <div className="text-center text-[30px] text-[#ba9449] hover:text-[#ebc3c3]">
                        <p>Blog articles</p>
                    </div>
                    <div className="inner mt-5">
                        <div className="t1 w-[100%] grid grid-cols-1 gap-0 min-[600px]:grid-cols-2 min-[600px]:gap-8  box-border">
                            <div className="t-1 border-b-[2px] border-[#ba9449] ">
                                <a href="#" className='font-poppins text-[#ba9449] hover:text-[red] hover:underline'>Security</a>
                                <h2 className='font-poppins font-semibold mt-1'>Cyera acquires Trail Security for $162M; Cyera is now raising at a $3B valuation</h2>
                                <h3 className='font-poppins text-[15px] mt-2 pb-3 opacity-50'>Ingrid Lunden-16 mins ago</h3>
                            </div>
                            <div className="t-1 mt-4 border-b-[2px] border-[#ba9449] ">
                                <a href="#" className='font-poppins text-[#ba9449] hover:text-[red] hover:underline'>Startups</a>
                                <h2 className='font-poppins font-semibold mt-1'>Zepto eyes $100M from Indian offices in third funding in 6 months</h2>
                                <h3 className='font-poppins text-[15px] mt-2 pb-3 opacity-50'>Manish Singh-
                                    4 hours ago</h3>
                            </div>
                            <div className="t-1 mt-4 border-b-[2px] border-[#ba9449] ">
                                <a href="#" className='font-poppins text-[#ba9449] hover:text-[red] hover:underline'>Commerce</a>
                                <h2 className='font-poppins font-semibold mt-1'>Accel eyes stake in India’s Truemeds at $330 million valuation</h2>
                                <h3 className='font-poppins text-[15px] mt-2 pb-3 opacity-50'>Manish Singh-
                                    5 hours ago</h3>
                            </div>
                            <div className="t-1 mt-4 border-b-[2px] border-[#ba9449] ">
                                <a href="#" className='font-poppins text-[#ba9449] hover:text-[red] hover:underline'>Enterprise</a>
                                <h2 className='font-poppins font-semibold mt-1'>Meta lays off employees across multiple teams</h2>
                                <h3 className='font-poppins text-[15px] mt-2 pb-3 opacity-50'>Maxwell Zeff-6 hours ago</h3>
                            </div>
                            <div className="t-1 mt-4 border-b-[2px] border-[#ba9449] ">
                                <a href="#" className='font-poppins text-[#ba9449] hover:text-[red] hover:underline'>AI</a>
                                <h2 className='font-poppins font-semibold mt-1'>Meta’s AI chief says world models are key to ‘human-level AI’ — but it might be 10 years out</h2>
                                <h3 className='font-poppins text-[15px] mt-2 pb-3 opacity-50'>Maxwell Zeff-6 hours ago</h3>
                            </div>
                            <div className="t-1 border-b-[2px] border-[#ba9449] ">
                                <a href="#" className='font-poppins text-[#ba9449] hover:text-[red] hover:underline'>Security</a>
                                <h2 className='font-poppins font-semibold mt-1'>Cyera acquires Trail Security for $162M; Cyera is now raising at a $3B valuation</h2>
                                <h3 className='font-poppins text-[15px] mt-2 pb-3 opacity-50'>Ingrid Lunden-16 mins ago</h3>
                            </div>
                            <div className="t-1 mt-4 border-b-[2px] border-[#ba9449] ">
                                <a href="#" className='font-poppins text-[#ba9449] hover:text-[red] hover:underline'>Startups</a>
                                <h2 className='font-poppins font-semibold mt-1'>Zepto eyes $100M from Indian offices in third funding in 6 months</h2>
                                <h3 className='font-poppins text-[15px] mt-2 pb-3 opacity-50'>Manish Singh-
                                    4 hours ago</h3>
                            </div>
                            <div className="t-1 mt-4 border-b-[2px] border-[#ba9449] ">
                                <a href="#" className='font-poppins text-[#ba9449] hover:text-[red] hover:underline'>Commerce</a>
                                <h2 className='font-poppins font-semibold mt-1'>Accel eyes stake in India’s Truemeds at $330 million valuation</h2>
                                <h3 className='font-poppins text-[15px] mt-2 pb-3 opacity-50'>Manish Singh-
                                    5 hours ago</h3>
                            </div>
                            <div className="t-1 mt-4 border-b-[2px] border-[#ba9449] ">
                                <a href="#" className='font-poppins text-[#ba9449] hover:text-[red] hover:underline'>Enterprise</a>
                                <h2 className='font-poppins font-semibold mt-1'>Meta lays off employees across multiple teams</h2>
                                <h3 className='font-poppins text-[15px] mt-2 pb-3 opacity-50'>Maxwell Zeff-6 hours ago</h3>
                            </div>
                            <div className="t-1 mt-4 border-b-[2px] border-[#ba9449] ">
                                <a href="#" className='font-poppins text-[#ba9449] hover:text-[red] hover:underline'>AI</a>
                                <h2 className='font-poppins font-semibold mt-1'>Meta’s AI chief says world models are key to ‘human-level AI’ — but it might be 10 years out</h2>
                                <h3 className='font-poppins text-[15px] mt-2 pb-3 opacity-50'>Maxwell Zeff-6 hours ago</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-[150px] ">
                <Other2 />
            </div>
            <div className="footer">
                <Othe />
            </div>


        </>
    )
}

export default Home;