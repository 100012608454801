import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { Link } from "react-router-dom";
import { faEnvelope, faLocationDot, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import Othe from "./footer";
import { Navbar } from "flowbite-react";


const Blog = () => {
    return (
        <>
            <div className="hader bg-[#ba9449] w-[100%] py-[20px]">
                <div className="contaner">
                    <Navbar className="bg-[#ba9449] justify-between">
                        <Navbar.Brand href="#">
                            <div className="box-border	min-[767px]:w-[300px] min-[1020px]:w-[500px]">
                                <p className='text-[black] text-[25px] pb-[10px] mt-[15px] '><span className='text-[#ffffff]'>TECH</span>BLOG</p>
                            </div>
                        </Navbar.Brand>
                        <div className="flex md:order-2  min-[767px]:hidden">
                            <Navbar.Toggle />
                        </div>
                        <Navbar.Collapse className="min-[767px]:block">
                            <div className="grid grid-cols-1 gap-2 min-[767px]:grid-cols-5 min-[767px]:gap-0">
                                <Link to="/" className="hover:text-[#000000] text-[#ffffff]">Home</Link>
                                <Link to="/About" className="hover:text-[#000000] text-[#ffffff]" >About</Link>
                                <Link to="/Blog" className="hover:text-[#000000] text-[#ffffff]" >Blog</Link>
                                <Link to="/Privacy" className="hover:text-[#000000] text-[#ffffff]" >Privacy Policy</Link>
                                <Link to="/Contact" className="hover:text-[#000000] text-[#ffffff]" >Contact Us</Link>
                            </div>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            </div>


            <div className="blogsection  show-after-visible from-bottom">
                <section class="py-10">
                    <div class="contaner ">
                        <div class="text-2xl text-center font-semibold mb-10  hover:underline text-[#a17d34]"> ALL BLOG</div>
                        <div class="grid lg:grid-cols-3 gap-6">
                            <Link to="/Blogpage1">
                                <div class="relative group">
                                    <div class="overflow-hidden rounded-lg aspect-w-16 aspect-h-9">
                                        <img src="/img/download.jpg" className='object-cover w-full h-[250px] transition-all duration-300 transform group-hover:scale-125' alt="" />
                                    </div>
                                    <p class="mt-6 text-sm font-normal text-zinc-600 font-pj">November 9, 2024</p>
                                    <p class="mt-4 text-xl font-bold text-zinc-900 font-pj text-[#000000] ">Amazon Delivery Drone Noise Annoys Some Residents of Texas Town</p>
                                    <a href="#" title="">
                                        <span class="absolute inset-0" aria-hidden="true"></span>
                                    </a>
                                </div>
                            </Link>
                            <Link to="/Blogpage">
                                <div class="relative group">
                                    <div class="overflow-hidden rounded-lg aspect-w-16 aspect-h-9">
                                        <img src="/img/ai2.jpg" className='object-cover w-full h-[250px] transition-all duration-300 transform group-hover:scale-125' alt="" />
                                    </div>
                                    <p class="mt-6 text-sm font-normal text-zinc-600 font-pj">November 8, 2024</p>
                                    <p class="mt-4 text-xl font-bold text-zinc-900 text-[#000000]">The best free AI tools in 2025 </p>
                                    <a href="" title="">
                                        <span class="absolute inset-0" aria-hidden="true"></span>
                                    </a>
                                </div>
                            </Link>
                            <Link to="/Blogpage3">
                                <div class="relative group">
                                    <div class="overflow-hidden rounded-lg aspect-w-16 aspect-h-9">
                                        <img src="/img/ai.jpg" className='object-cover w-full h-[250px] transition-all duration-300 transform group-hover:scale-125' alt="" />
                                    </div>
                                    <p class="mt-6 text-sm font-normal text-zinc-600 font-pj">November 16, 2024</p>
                                    <p class="mt-4 text-xl font-bold text-zinc-900 font-pj text-[#000000]">AI For Website Building: Tools, Tips, and Trends for 2024</p>
                                    <a href="#" title="">
                                        <span class="absolute inset-0" aria-hidden="true"></span>
                                    </a>
                                </div>
                            </Link>
                        </div>
                    </div>
                </section>
                <section class="py-10">
                    <div class="contaner">
                        <div class="grid lg:grid-cols-3 gap-6">
                            <Link to="/Blogpage4">
                                <div class="relative group">
                                    <div class="overflow-hidden rounded-lg aspect-w-16 aspect-h-9">
                                        <img src="/img/robot.jpg" className='object-cover w-full h-[250px] transition-all duration-300 transform group-hover:scale-125' alt="" />
                                    </div>
                                    <p class="mt-6 text-sm font-normal text-zinc-600 font-pj">November 9, 2024</p>
                                    <p class="mt-4 text-xl font-bold text-zinc-900 font-pj text-[#000000] ">AI Robot Aims To Slash Cost of E-Commerce Deliveries</p>
                                    <a href="#" title="">
                                        <span class="absolute inset-0" aria-hidden="true"></span>
                                    </a>
                                </div>
                            </Link>
                            <Link to="/Blogpage5">
                                <div class="relative group">
                                    <div class="overflow-hidden rounded-lg aspect-w-16 aspect-h-9">
                                        <img src="/img/downloa.jpg" className='object-cover w-full h-[250px] transition-all duration-300 transform group-hover:scale-125' alt="" />
                                    </div>
                                    <p class="mt-6 text-sm font-normal text-zinc-600 font-pj">November 8, 2024</p>
                                    <p class="mt-4 text-xl font-bold text-zinc-900 text-[#000000]">AI won't take over humans, it will amplify intelligence,' says Meta AI chief Yann Lecun </p>
                                    <a href="#" title="">
                                        <span class="absolute inset-0" aria-hidden="true"></span>
                                    </a>
                                </div>
                            </Link>

                            <Link to="/Blogpage6">
                            <div class="relative group">
                                <div class="overflow-hidden rounded-lg aspect-w-16 aspect-h-9">
                                    <img src="/img/images.jpg" className='object-cover w-full h-[250px] transition-all duration-300 transform group-hover:scale-125' alt="" />
                                </div>
                                <p class="mt-6 text-sm font-normal text-zinc-600 font-pj">November 16, 2024</p>
                                <p class="mt-4 text-xl font-bold text-zinc-900 font-pj text-[#000000]">Anthropic releases AI to automate mouse clicks for coders</p>
                                <a href="#" title="">
                                    <span class="absolute inset-0" aria-hidden="true"></span>
                                </a>
                            </div>
                            </Link>

                        </div>
                    </div>
                </section>
                <section class="py-10">
                    <div class="contaner ">
                        <div class="grid lg:grid-cols-3 gap-6">
                            <Link to="/Blogpage1">
                                <div class="relative group">
                                    <div class="overflow-hidden rounded-lg aspect-w-16 aspect-h-9">
                                        <img src="/img/download.jpg" className='object-cover w-full h-[250px] transition-all duration-300 transform group-hover:scale-125' alt="" />
                                    </div>
                                    <p class="mt-6 text-sm font-normal text-zinc-600 font-pj">November 9, 2024</p>
                                    <p class="mt-4 text-xl font-bold text-zinc-900 font-pj text-[#000000] ">Amazon Delivery Drone Noise Annoys Some Residents of Texas Town</p>
                                    <a href="#" title="">
                                        <span class="absolute inset-0" aria-hidden="true"></span>
                                    </a>
                                </div>
                            </Link>
                            <Link to="/Blogpage">
                                <div class="relative group">
                                    <div class="overflow-hidden rounded-lg aspect-w-16 aspect-h-9">
                                        <img src="/img/ai2.jpg" className='object-cover w-full h-[250px] transition-all duration-300 transform group-hover:scale-125' alt="" />
                                    </div>
                                    <p class="mt-6 text-sm font-normal text-zinc-600 font-pj">November 8, 2024</p>
                                    <p class="mt-4 text-xl font-bold text-zinc-900 text-[#000000]">The best free AI tools in 2025 </p>
                                    <a href="" title="">
                                        <span class="absolute inset-0" aria-hidden="true"></span>
                                    </a>
                                </div>
                            </Link>
                            <Link to="/Blogpage3">
                                <div class="relative group">
                                    <div class="overflow-hidden rounded-lg aspect-w-16 aspect-h-9">
                                        <img src="/img/ai.jpg" className='object-cover w-full h-[250px] transition-all duration-300 transform group-hover:scale-125' alt="" />
                                    </div>
                                    <p class="mt-6 text-sm font-normal text-zinc-600 font-pj">November 14, 2024</p>
                                    <p class="mt-4 text-xl font-bold text-zinc-900 font-pj text-[#000000]">AI For Website Building: Tools, Tips, and Trends for 2024</p>
                                    <a href="#" title="">
                                        <span class="absolute inset-0" aria-hidden="true"></span>
                                    </a>
                                </div>
                            </Link>
                        </div>
                    </div>
                </section>
            </div>
            <div className="buttan py-5 ml-[47%]">
                <button className="w-[100px] h-[25px] bg-[#e0be79]">
                <a href="https://echonverforrinho.info?tid=11190623">NEXT PAGE</a>
                    
                </button>
            </div>
            <div className="footer">
                <Othe />
            </div>
        </>
    )

}

export default Blog;