import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

function Other2() {
    return (
        <>
            <div className="text-center">
                <p className='text-[30px] text-[#a17d34] mb-[30px]'>Viewer Reviews</p>
            </div>
          <Slide>
            <div className="each-slide-effect w-[600px] ml-[30%] text-[25px] text-center font-Varela
">
                <div>
                    <span className=''>SpaceX sues California agency, alleging political bias against Musk and regulatory overreach</span>
                    <p className='text-[15px] mt-[15px]'>Eric K.</p>
                    <p className='text-[15px]'>Online store owner</p>
                </div>
            </div>
            <div className="each-slide-effect w-[600px] ml-[31%] text-[25px] text-center font-Varela
">
                <div>
                    <span className=''>​​Amazon jumps on nuclear power bandwagon by investing in X-Energy and promising small reactors</span>               
                    <p className='text-[15px] mt-[15px] text-black'>Tim De Chant</p>
                    <p className='text-[15px] text-black opacity-80'>October 16, 2024</p>
                </div>
            </div>
            <div className="each-slide-effect w-[600px] ml-[31%] text-[25px] text-center font-Varela
">
                <div>
                    <span className=''>From quickly updating our page to implementing our online store, our WordPress.com site has become the online center for everything we do.</span>
                    <p className='text-[15px] mt-[15px]'>Eric K.</p>
                    <p className='text-[15px]'>Online store owner</p>
                </div>
            </div>
        </Slide>
        </>
    );
}

export default Other2;
