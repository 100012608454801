import React from 'react';
import { Clock, User, Tag, ArrowLeft } from 'lucide-react';
import Othe from './footer';



const BlogContent1 = () => {
  const post = {
    title: "Technology",
    author: "Sarah Mitchell",
    date: "November 14, 2024",
    readTime: "8 min read",
    tags: ["Beginners", "Morning Routine", "Wellness"],
    content: `
      How a developer and designer duo at Deutsche Bank keep remote alive.

      A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy.
      
      There are approximately a zillion AI tools out there, promising to help you with everything from basic research to building a website. But if you're a small business or individual, you don't have the budget to pay for every flashy new tool you stumble across.
      
      The good news: there are plenty of free AI tools that let you experiment with incorporating AI into your business processes—and even get great results—without paying a cent. Here's a giant roundup of the best free AI apps available in every category, from chatbots and writing generators to sales assistants and social media managers.
    `
  };

  const relatedPosts = [
    {
      title: "The Power of Breath Work",
      description: "Explore various pranayama techniques to enhance your practice.",
      imageAlt: "Meditation session"
    },
    {
      title: "Creating a Home Yoga Space",
      description: "Tips for setting up the perfect yoga corner in your home.",
      imageAlt: "Home yoga space"
    }
  ];

  return (
    <>
      <div className="bg-[#e0be79] min-h-screen">
        {/* Main content */}
        <article className="max-w-4xl mx-auto bg-white rounded-lg shadow-md overflow-hidden">
          <img
            src="/img/Technology.avif"
            alt=""
            className="w-full h-72 object-cover"
          />

          <div className="p-8">
            {/* Article header */}
            <div className="mb-8">
              <h1 className="text-4xl font-bold text-gray-800 mb-4">{post.title}</h1>
              <div className="flex flex-wrap gap-4 text-gray-600">
                <div className="flex items-center">
                  <User className="w-4 h-4 mr-2" />
                  {post.author}
                </div>
                <div className="flex items-center">
                  <Clock className="w-4 h-4 mr-2" />
                  {post.date} • {post.readTime}
                </div>
              </div>
            </div>

            {/* Tags */}
            <div className="flex flex-wrap gap-2 mb-8">
              {post.tags.map((tag, index) => (
                <span
                  key={index}
                  className="bg-orange-100 text-orange-800 px-3 py-1 rounded-full text-sm"
                >
                  <Tag className="w-3 h-3 inline mr-1" />
                  {tag}
                </span>
              ))}
            </div>

            {/* Article content */}
            <div className="prose max-w-none">
              {post.content.split('\n\n').map((paragraph, index) => (
                <p key={index} className="mb-6 text-gray-700 leading-relaxed">
                  {paragraph}
                </p>
              ))}
            </div>
          </div>
        </article>

        {/* Related posts */}
        <div className="max-w-4xl mx-auto mt-12 px-4 mb-12">
          <h2 className="text-2xl font-bold text-gray-800 mb-6">Related Articles</h2>
          <div className="grid md:grid-cols-2 gap-8">
            {relatedPosts.map((post, index) => (
              <article key={index} className="bg-white rounded-lg shadow-md overflow-hidden">
                <img
                  src="../img/run2.avif"
                  alt={post.imageAlt}
                  className="w-full h-48 object-cover"
                />
                <div className="p-6">
                  <h3 className="text-xl font-bold text-gray-800 mb-2">{post.title}</h3>
                  <p className="text-gray-600 mb-4">{post.description}</p>
                  <a href="#" className="text-orange-600 hover:text-orange-800">
                    Read More →
                  </a>
                </div>
              </article>
            ))}
          </div>
        </div>
        <div className="footer">
          <Othe />
        </div>
      </div>

    </>



  );
};

export default BlogContent1;