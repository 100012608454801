import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { faEnvelope, faLocationDot, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import Othe from "./footer";
import { Navbar } from "flowbite-react";


const About = () => {
    return (
        <div>
            <div className="hader bg-[#ba9449] w-[100%] py-[20px]">
                <div className="contaner">
                <Navbar className="bg-[#ba9449] justify-between">
                <Navbar.Brand href="#">
                    <div className="box-border	min-[767px]:w-[300px] min-[1020px]:w-[500px]">
                        <p className='text-[black] text-[25px] pb-[10px] mt-[15px] '><span className='text-[#ffffff]'>TECH</span>BLOG</p>
                    </div>
                </Navbar.Brand>
                <div className="flex md:order-2  min-[767px]:hidden">
                    <Navbar.Toggle />
                </div>
                <Navbar.Collapse className="min-[767px]:block">
                    <div className="grid grid-cols-1 gap-2 min-[767px]:grid-cols-5 min-[767px]:gap-0">
                        <Link to="/" className="hover:text-[#000000] text-[#ffffff]">Home</Link>
                        <Link to="/About" className="hover:text-[#000000] text-[#ffffff]" >About</Link>
                        <Link to="/Blog" className="hover:text-[#000000] text-[#ffffff]" >Blog</Link>
                        <Link to="/Privacy" className="hover:text-[#000000] text-[#ffffff]" >Privacy Policy</Link>
                        <Link to="/Contact" className="hover:text-[#000000] text-[#ffffff]" >Contact Us</Link>
                    </div>
                </Navbar.Collapse>
            </Navbar>
                </div>
            </div>

            <div className="aboutpage">
                <div className="hader h-[150px]">
                    <h3 className="font-Rubik text-[30px] text-[#000000] text-center mt-[75px]">Home / About us</h3>
                </div>


                <div className="w-[100%] mb-5  bg-opacity-95 ">
                    <div className="contaner ">
                        <div className="bg-[#edcc8a] rounded-lg ">
                            <div className="text-[30px]">
                                <p className="text-[#000000] pl-[20px] pt-[45px]"><span className="text-[#a17d34] underline decoration-dashed underline-[red]">Our Team</span> & Guide</p>
                            </div>
                            <div className="grid grid-cols-1 gap-4 min-[600px]:grid-cols-2 min-[1020px]:grid-cols-4  min-[600px]:gap-5 mt-[20px] px-[20px] pb-[30px]">
                                <div className="b1 box-border w-[100%]">
                                    <img src="../img/team1.jpeg" alt="" className="w-[100%] h-[400px]" />
                                    <div className="box-border text-center hover:translate-y-[-30px] duration-700 border-2 bg-[#a17d34] border-[#a17d34]">
                                        <p>Larry Page</p>
                                        <p>Cheif Officer</p>
                                    </div>
                                </div>
                                <div className="b2 box-border w-[100%]">
                                    <img src="../img/render.jpeg" alt="" className="w-[100%] h-[400px]" />
                                    <div className="box-border text-center hover:translate-y-[-30px] duration-700 border-2 bg-[#a17d34] border-[#a17d34]">
                                        <p>MacKenzie Scott</p>
                                        <p>Head of Department</p>
                                    </div>
                                </div>
                                <div className="b3 box-border w-[100%]">
                                    <img src="../img/team3.avif" alt="" className="w-[100%] h-[400px]" />
                                    <div className="box-border text-center hover:translate-y-[-30px] duration-700 border-2 bg-[#a17d34] border-[#a17d34]">
                                        <p>Jensen Huang</p>
                                        <p>Manager</p>
                                    </div>
                                </div>
                                <div className="b4 box-border w-[100%]">
                                    <img src="../img/team4.avif" alt="" className="w-[100%] h-[400px]" />
                                    <div className="box-border text-center hover:translate-y-[-30px] duration-700 border-2 border-[#a17d34] bg-[#a17d34]">
                                        <p>Larry Ellison</p>
                                        <p>CO-CEO</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer">
                <Othe />
            </div>
        </div>

    )

}

export default About;