
import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const Example = () => {

    return (
        <Slide className="">
            <div className="each-slide-effect">
                <div>
                    <span><img src="/img/tech.jpeg" alt="" className='w-[100%] h-[650px] opacity-1' /></span>
                    
                </div>
            </div>
            <div className="each-slide-effect">
                <div>
                    <span><img src="/img/tech2.jpeg" alt="" className='w-[100%] h-[650px] opacity-1' /></span>
                </div>
            </div>
            <div className="each-slide-effect">
                <div>
                    <span><img src="/img/tech3.jpeg" alt="" className='w-[100%] h-[650px] opacity-1' /></span>
                </div>
            </div>
        </Slide>
    );
};

export default Example;