import React from 'react'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { faCalendar, faEnvelope, faLocationDot, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import Othe from "./footer";
import { Navbar } from "flowbite-react";

function Privacy() {
    return (
        <div>
            <>
                <div className="hader bg-[#ba9449] w-[100%] py-[20px]">
                    <div className="contaner">
                        <Navbar className="bg-[#ba9449] justify-between">
                            <Navbar.Brand href="#">
                                <div className="box-border	min-[767px]:w-[300px] min-[1020px]:w-[500px]">
                                    <p className='text-[black] text-[25px] pb-[10px] mt-[15px] '><span className='text-[#ffffff]'>TECH</span>BLOG</p>
                                </div>
                            </Navbar.Brand>
                            <div className="flex md:order-2  min-[767px]:hidden">
                                <Navbar.Toggle />
                            </div>
                            <Navbar.Collapse className="min-[767px]:block">
                                <div className="grid grid-cols-1 gap-2 min-[767px]:grid-cols-5 min-[767px]:gap-0">
                                    <Link to="/" className="hover:text-[#000000] text-[#ffffff]">Home</Link>
                                    <Link to="/About" className="hover:text-[#000000] text-[#ffffff]" >About</Link>
                                    <Link to="/Blog" className="hover:text-[#000000] text-[#ffffff]" >Blog</Link>
                                    <Link to="/Privacy" className="hover:text-[#000000] text-[#ffffff]" >Privacy Policy</Link>
                                    <Link to="/Contact" className="hover:text-[#000000] text-[#ffffff]" >Contact Us</Link>
                                </div>
                            </Navbar.Collapse>
                        </Navbar>
                    </div>
                </div>
                <div className="">
                    <div className="hader h-[150px]">
                        <h3 className="font-Rubik text-[30px] text-[black] text-center mt-[75px]">Home / Privacy Policy</h3>
                    </div>
                    <div className="w-[100%] py-[4%] bg-[#edcc8a] bg-opacity-95 ">
                        <div className="contaner">
                            <div className="grid grid-cols-1 gap-5 min-[1020px]:flex">
                                <div className="bg-[#ffffff]  box-border  w-[100%] h-[505px] px-[30px]">
                                    <ul>
                                        <li className="mt-8 text-[40px]"><span className="text-[#dbb25f]">P</span>rivacy <span className="text-[#dbb25f]">P</span>olicy</li>
                                        <li><p className="mt-[20px] text-[20px]">This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p></li>
                                        <li><p className="mt-[10px] text-[20px]">We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help .</p></li>
                                        <li><p className="mt-[10px] text-[20px]">We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p></li>

                                    </ul>
                                </div>
                                <div className="bg-[#ffffff]  box-border  w-[100%] min-[1020px]:w-[30%] h-[505px] ml-[30px] px-[30px]">
                                    <div className="mt-8 text-[40px] text-[#dbb25f]">
                                        <p>Recommended</p>
                                    </div>
                                    <div className="">
                                        <div className="flex mt-[20px]">
                                            <div className="">
                                                <img src="../img/laptop.jpg" alt="" className="w-[239px] h-[100px]" />
                                            </div>
                                            <div className="ml-[10px]">
                                                <a href="#"><p className="hover:text-[#bd923b] hover:underline">Facts About Business That Will Help You Success</p></a>
                                                <p className="mt-[27px]"><FontAwesomeIcon icon={faCalendar} /> 22 Mar 2021</p>
                                            </div>
                                        </div>
                                        <div className="flex mt-[20px]">
                                            <div className="">
                                                <img src="../img/laptop2.jpg" alt="" className="w-[209px] h-[100px]" />
                                            </div>
                                            <div className="ml-[10px]">
                                                <a href="#"><p className="hover:text-[#bd923b] hover:underline">Your Light Is About To Stop Being Relevant</p></a>
                                                <p className="mt-[27px]"><FontAwesomeIcon icon={faCalendar} /> 22 Mar 2021</p>
                                            </div>
                                        </div>
                                        <div className="flex mt-[20px]">
                                            <div className="">
                                                <img src="../img/laptop3.jpg" alt="" className="w-[196px] h-[100px]" />
                                            </div>
                                            <div className="ml-[10px]">
                                                <a href="#"><p className="hover:text-[#bd923b] hover:underline">3 Easy Ways To Make Your iPhone Faster</p></a>
                                                <p className="mt-[27px]"><FontAwesomeIcon icon={faCalendar} /> 22 Mar 2021</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="footer">
                    <Othe />
                </div>
            </>
        </div>
    )
}

export default Privacy
