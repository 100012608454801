
import React from 'react';

function Othe() {
    return (
        <>
                        <footer className='bg-[#ba9449] w-[100%] py-[30px]'>
                <div className="contaner">
                    <div className="main grid grid-cols-1 gap-0 min-[420px]:grid-cols-2 min-[420px]:gap-3 min-[768px]:grid-cols-4 min-[600px]:gap-4 pb-[17px] border-b-[2px] border-b-[#bdbab3] ">
                        <div className="mb-4">
                            <ul>
                                <li><p className='text-[black] text-[25px] pb-[10px] '><span className='text-[#ffffff]'>TECH</span>BLOG</p></li>
                                <li><p className='hidden min-[420px]:block'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Porro qui incidunt vitae numquam iste officiis officia totam.</p></li>
                                <li>+47-252-254-2542</li>
                                <li>Collins Street, sydney, Australia</li>
                                <li>info@Techblog.com</li>
                                <li>www.Techblog.com</li>
                            </ul>
                        </div>
                        <div className="">
                            <ul>
                                <li>
                                    <h2 class="pb-4 text-[20px] font-semibold text-white uppercase ">Company</h2>
                                </li>
                                <li class="mb-3"><a href="About" class=" hover:underline hover:text-[#a17d34]">About</a></li>
                                <li class="mb-3"><a href="#" class="hover:underline hover:text-[#a17d34]">Careers</a></li>
                                <li class="mb-3"><a href="#" class="hover:underline hover:text-[#a17d34]">Brand Center</a></li>
                                <li class="mb-3"><a href="Blog" class="hover:underline hover:text-[#a17d34]">Blog</a></li>
                            </ul>
                        </div>
                        <div className="">
                            <ul>
                                <li>
                                    <h2 class="pb-4 text-[20px] font-semibold text-white uppercase ">Help center</h2>
                                </li>
                                <li class="mb-4">
                                    <a href="#" class="hover:underline hover:text-[#a17d34]">Discord Server</a>
                                </li>
                                <li class="mb-4">
                                    <a href="#" class="hover:underline hover:text-[#a17d34]">Twitter</a>
                                </li>
                                <li class="mb-4">
                                    <a href="#" class="hover:underline hover:text-[#a17d34]">Facebook</a>
                                </li>
                                <li class="mb-4">
                                    <a href="#" class="hover:underline hover:text-[#a17d34]">Contact Us</a>
                                </li>
                            </ul>
                        </div>
                        <div className="">
                            <ul>
                                <h2 class="pb-4 text-[20px] font-semibold text-white uppercase "> Newsletter</h2>
                                <li><h2></h2></li>
                                <li><p>Want to be notified when we launch a new template or an udpate. Just sign up and we'll send you a notification by email.</p></li>
                                <li><input type="email" id="" placeholder='Enter your email' className='w-[100%] h-[40px] mt-[15px] bg-[#d7dadb] focus:outline-[#d7dadb] pl-[20px]' /></li>
                                <li><input type="submit" value="submit" className='bg-[#a17d34] w-[100%] h-[40px] mt-[20px]' /></li>
                            </ul>
                        </div>
                    </div>
                    <div className=" pt-[10px]">
                        Copyright 1999-2024 by tech Company. All Rights Reserved. techblog is Powered by sunriseinfo.
                    </div>

                </div>
            </footer>

        </>
    )
}

export default Othe
