import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './Hader.css';
import Hader from './Hader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import Example from './slider';
import Other from './footer';
import Other2 from './other2';
import Oth3 from './Other3';
import Raj from './dropdown';
import MyForm from './dropdown';
import My from './dropdown';
import Other4 from './Other4';
import Othe from './footer';
import Risponsive from './Risponsive';
import Demo from './Demo';
import BlogPost from './Blog1';
import HomePage from './Other';
import BlogContent from './Blog1';
 


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <>
            <Hader/>
            {/* <Other2/> */}
            {/* <Oth3/> */}
            {/* <Raj/> */}
            {/* <My/> */}
            {/* <Othe/> */}
            {/* <Other4/> */}
            {/* <Risponsive/> */}
            {/* <Demo/> */}
            {/* <BlogPost/> */}
            {/* <HomePage/> */}
            {/* <BlogContent/> */}
        </>
);
