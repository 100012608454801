import React, { useState } from 'react';
import { MessageCircle, ThumbsUp, Share2, Bookmark, Clock, Tag } from 'lucide-react';


const BlogPost1 = () => {
    const [isLiked, setIsLiked] = useState(false);
    const [likeCount, setLikeCount] = useState(42);
    const [comments, setComments] = useState([
        {
            id: 1,
            author: "Sarah Johnson",
            content: "Great article! The insights about AI-powered development are particularly interesting. I've been using some of these tools in my workflow and they're game-changers.",
            timestamp: "2 hours ago",
            avatar: "/api/placeholder/40/40"
        },
        {
            id: 2,
            author: "Mike Chen",
            content: "The section about Web Performance Optimization is crucial. Core Web Vitals have become so important for modern web development.",
            timestamp: "5 hours ago",
            avatar: "/api/placeholder/40/40"
        }
    ]);
    const [newComment, setNewComment] = useState('');

    const handleLike = () => {
        setIsLiked(!isLiked);
        setLikeCount(isLiked ? likeCount - 1 : likeCount + 1);
    };

    const handleCommentSubmit = (e) => {
        e.preventDefault();
        if (newComment.trim()) {
            const comment = {
                id: comments.length + 1,
                author: "Current User",
                content: newComment,
                timestamp: "Just now",
                avatar: "/api/placeholder/40/40"
            };
            setComments([comment, ...comments]);
            setNewComment('');
        }
    };

    return (
        <div className="max-w-4xl mx-auto px-4 py-8">
            {/* Article Header */}
            <article className="bg-white rounded-lg shadow-sm overflow-hidden">
                <img
                    src="/img/download.jpg"
                    alt="Blog post cover"
                    className="w-full h-72 object-cover"
                />

                <div className="p-8">
                    {/* Meta Information */}
                    <div className="flex items-center space-x-4 text-sm text-gray-500 mb-4">
                        <span className="flex items-center">
                            <Clock className="w-4 h-4 mr-1" />
                            November 12, 2024
                        </span>
                        <span>•</span>
                        <span>5 min read</span>
                        <span>•</span>
                        <span className="text-blue-600 hover:underline cursor-pointer">Technology</span>
                    </div>

                    {/* Title */}
                    <h1 className="text-4xl font-bold text-gray-900 mb-4">
                    Amazon Delivery Drone Noise Annoys Some Residents of Texas Town
                    </h1>

                    {/* Author Info */}
                    <div className="flex items-center space-x-3 mb-8">
                        <img
                            src="/img/user.avif"
                            alt="Author"
                            className="w-10 h-10 rounded-full"
                        />
                        <div>
                            <p className="text-gray-900 font-medium">John Doe</p>
                            <p className="text-gray-500 text-sm">Senior Web Developer</p>
                        </div>
                    </div>

                    {/* Content */}
                    <div className="prose max-w-none">
                        <p className="text-gray-700 mb-6">
                            The web development landscape is constantly evolving, bringing new technologies
                            and methodologies that shape how we build digital experiences. In this article,
                            we'll explore the most significant trends that are transforming web development in 2024.
                        </p>

                        <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">
                            1. The Rise of AI-Powered Development
                        </h2>
                        <p className="text-gray-700 mb-6">
                            Artificial Intelligence is revolutionizing how developers work, from code completion
                            to automated testing. We're seeing a surge in AI-powered tools that help developers
                            write better code faster and more efficiently.
                        </p>

                        <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">
                            2. Web Performance Optimization
                        </h2>
                        <p className="text-gray-700 mb-6">
                            With Core Web Vitals becoming increasingly important for SEO and user experience,
                            developers are focusing more on performance optimization techniques and tools.
                        </p>

                        {/* Code Example */}
                        <div className="bg-gray-50 rounded-lg p-4 my-6">
                            <pre className="text-sm text-gray-800">
                                {`// Example of modern JavaScript code
const performanceOptimization = async () => {
    await loadContent();
    measureMetrics();
    optimizeImages();
};`}
                            </pre>
                        </div>
                    </div>

                    {/* Tags */}
                    <div className="flex flex-wrap gap-2 mt-8">
                        <span className="inline-flex items-center px-3 py-1 bg-gray-100 text-gray-700 rounded-full text-sm">
                            <Tag className="w-4 h-4 mr-1" />
                            WebDev
                        </span>
                        <span className="inline-flex items-center px-3 py-1 bg-gray-100 text-gray-700 rounded-full text-sm">
                            <Tag className="w-4 h-4 mr-1" />
                            Technology
                        </span>
                        <span className="inline-flex items-center px-3 py-1 bg-gray-100 text-gray-700 rounded-full text-sm">
                            <Tag className="w-4 h-4 mr-1" />
                            Programming
                        </span>
                    </div>

                    {/* Action Buttons */}
                    <div className="flex items-center space-x-6 mt-8 pt-8 border-t">
                        <button
                            onClick={handleLike}
                            className={`flex items-center space-x-2 ${isLiked ? 'text-blue-600' : 'text-gray-600'}`}
                        >
                            <ThumbsUp className="w-5 h-5" />
                            <span>{likeCount}</span>
                        </button>
                        <button className="flex items-center space-x-2 text-gray-600">
                            <MessageCircle className="w-5 h-5" />
                            <span>{comments.length}</span>
                        </button>
                        <button className="flex items-center space-x-2 text-gray-600">
                            <Share2 className="w-5 h-5" />
                        </button>
                        <button className="flex items-center space-x-2 text-gray-600 ml-auto">
                            <Bookmark className="w-5 h-5" />
                        </button>
                    </div>
                </div>
            </article>

            {/* Comments Section */}
            <section className="mt-8 bg-white rounded-lg shadow-sm p-8">
                <h2 className="text-2xl font-bold text-gray-900 mb-6">Comments</h2>

                {/* Comment Form */}
                <form onSubmit={handleCommentSubmit} className="mb-8">
                    <textarea
                        className="w-full p-4 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        rows="4"
                        placeholder="Leave a comment..."
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                    />
                    <button
                        type="submit"
                        className="mt-4 px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                    >
                        Post Comment
                    </button>
                </form>

                {/* Comments List */}
                <div className="space-y-6">
                    {comments.map(comment => (
                        <div key={comment.id} className="flex space-x-4">
                            <img
                                src="/img/user.avif"
                                alt={comment.author}
                                className="w-10 h-10 rounded-full"
                            />
                            <div>
                                <div className="flex items-center space-x-2">
                                    <h3 className="font-medium text-gray-900">{comment.author}</h3>
                                    <span className="text-gray-500 text-sm">{comment.timestamp}</span>
                                </div>
                                <p className="text-gray-700 mt-1">{comment.content}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default BlogPost1;