import React from "react";

import { BrowserRouter,Route,Routes } from "react-router-dom";
import Home from "./Home";
import About from "./About";
import Privacy from "./Privacy";
import Blog from "./Blog";
import Contact from "./Contact";
import Othe from "./footer";
import BlogPost from "./Blog1";
import BlogPost1 from "./Blogpage1";
import BlogPost3 from "./Blogpage3";
import BlogPost4 from "./Blogpage4";
import BlogPost5 from "./Blogpage5";
import BlogPost6 from "./Blogpage6";
import BlogContent1 from "./Blog1";
import BlogContent2 from "./Blog2";
import BlogContent3 from "./Blog3";
import BlogPost2 from "./Blogpage";


function Hader(){
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/About" element={<About/>}/>
                <Route path="/Privacy" element={<Privacy/>}/>
                <Route path="/Blog" element={<Blog/>}/>
                <Route path="/Contact" element={<Contact/>}/>   
                <Route path="/Othe" element={<Othe/>}/>
                <Route path="/Blogpage1" element={<BlogPost1/>}/> 
                <Route path="/Blogpage" element={<BlogPost2/>}/>  
                <Route path="/Blogpage3" element={<BlogPost3/>}/>     
                <Route path="/Blogpage4" element={<BlogPost4/>}/>     
                <Route path="/Blogpage5" element={<BlogPost5/>}/>
                <Route path="/Blogpage6" element={<BlogPost6/>}/>
                <Route path="/Blog1" element={<BlogContent1/>}/>
                <Route path="/Blog2" element={<BlogContent2/>}/>
                <Route path="/Blog3" element={<BlogContent3/>}/>


            </Routes>
        </BrowserRouter>
    )

}

export default Hader;